<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import Loader from '@/components/widgets/loader';
import Swal from 'sweetalert2'

import userGetAll from '../../../state/api/userFetAll';
import userChangeStatus from '../../../state/api/userChangeStatus';
import userCopy from '../../../state/api/userCopy';
export default {
    page: {
        title: "Contact Users List",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader,Loader },
    data() {
        return {
            lang: "en",
            title: "User List",
            items: [
                {
                text: "Users",
                href: "/"
                },
                {
                text: "User List",
                active: true
                }
            ],
            userList : [],
            searchText:"",
            page: 1,
            perPage: 10,
            pages: [],
        };        
    },
    methods:{
        async getUserList(){
            const {userList} = userGetAll()
            const response = await userList();
            this.userList = response.data;
            this.setPages(this.userList)
        },
        async userCopy(id){
            const {getUserCopy} = userCopy()
            const response = await getUserCopy(id);
            if(response.data){
              Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500
              })
              this.getUserList();
            }
        },
        isActiveToggle(id,durum){
          const data = this.userList;
            async function statusUser() {
                const {changeStatus} = userChangeStatus()
                await changeStatus(id,durum).
                then(res=>{
                    if(res.isSuccess){
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        data.filter(x=>x.id == id)[0].isActive =!data.filter(x=>x.id == id)[0].isActive;
                    }
                })
            }
            statusUser();
        },
        setPages(data) {
          let numberOfPages = Math.ceil(data.length / this.perPage);
          for (let index = 1; index <= numberOfPages; index++) {
            this.pages.push(index);
          }
        },
        paginate(data) {
          let page = this.page;
          let perPage = this.perPage;
          let from = page * perPage - perPage;
          let to = page * perPage;
          return data.slice(from, to);
        },
    },
    computed: {
        filteredList() {
            if(this.searchText !=""){
              return this.userList.filter(item => {
                  return item.firstName.toLowerCase().includes(this.searchText.toLowerCase())
              })
            }
            else{
              return this.displayedPosts.filter(item => {
                  return item.firstName.toLowerCase().includes(this.searchText.toLowerCase())
              })
            }
            
        },
        displayedPosts() {
          return this.paginate(this.userList);
        },
    },
    mounted(){
        this.getUserList();
    },
}
</script>

<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
        <Loader :loading="filteredList">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                      <div class="col-md-9">
                          <!-- <div class="btn-group mb-3">
                              <router-link class="btn btn-light dropdown-toggle btn-block" :to="{name:'user-add'}">
                                <i class="mdi mdi-plus me-1"></i> {{$t('user.UserNew')}}
                              </router-link>
                          </div> -->
                      </div>
                      <div class="col-md-3">
                          <div class="mb-3 float-end w-100">
                              <input class="form-control" type="search" :placeholder="$t('navbar.search.text')" v-model="searchText">
                          </div>
                      </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table align-middle table-nowrap table-hover">
                      <thead class="table-light">
                        <tr>
                          <th scope="col" style="width: 70px;">#</th>
                          <th scope="col">{{$t('user.Name')}}</th>
                          <th scope="col">{{$t('user.Email')}}</th>
                          <th scope="col">{{$t('user.Brand')}}</th>
                          <th scope="col">{{$t('user.Status')}}</th>
                          <th scope="col">{{$t('user.Action')}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="user in filteredList" :key="user.id">
                          <td>
                            <div class="avatar-xs">                          
                              <span class="avatar-title rounded-circle">{{ user.firstName.charAt(0) }}</span>
                            </div>
                          </td>
                          <td>
                            <h5 class="font-size-14 mb-1">
                              <router-link :to="{name:'UserProfili',params:{id:user.id}}" class="text-dark">{{user.firstName}} {{user.lastName}}</router-link>
                            </h5>
                            <p class="text-muted mb-0">{{user.companyName}}</p>
                          </td>
                          <td>{{ user.userName }}</td>
                          <td>{{ user.userType.name }}</td>
                          <td v-if="user.isActive">
                            <button @click="isActiveToggle(user.id, user.isActive)" class="badge bg-success border-0">{{$t('user.Active')}}</button>
                          </td>
                          <td v-else>
                            <button @click="isActiveToggle(user.id, user.isActive)" class="badge bg-danger border-0">{{$t('user.Passive')}}</button>
                          </td>                          
                          <td>
                            <ul class="list-inline font-size-20 contact-links mb-0">
                              <li class="list-inline-item px-2 d-none">
                                <router-link :to="{name:'userDetail',params:{id:user.id}}" class="text-dark" v-b-tooltip.hover :title="$t('menuitems.edit.text')" >
                                  <i class="bx bx-pencil"></i>
                                </router-link>
                              </li>
                              <li class="list-inline-item px-2 d-none">
                                <router-link :to="{name:'UserProfili',params:{id:user.id}}" class="text-dark" v-b-tooltip.hover :title="$t('user.Profile')" >
                                  <i class="bx bx-user-circle"></i>
                                </router-link>
                              </li>
                              <li class="list-inline-item px-2 d-none">
                                <button v-b-tooltip.hover :title="$t('user.Copy')" class="border-0 bg-transparent" @click="userCopy(user.id)">
                                  <i class="bx bx-copy"></i>
                                </button>
                              </li>
                              <li class="list-inline-item px-2 d-none">
                                <button v-b-tooltip.hover :title="$t('user.Delete')" class="text-danger border-0 bg-transparent">
                                    <i class="bx bx-trash-alt"></i>
                                </button>
                              </li>   
                              <li class="list-inline-item px-2">
                                <router-link :to="{name:'userCategoryEdit',params:{id:user.id}}" class="text-dark" v-b-tooltip.hover :title="$t('user.associateCategory')" >
                                  <i class="bx bx-link"></i>
                                </router-link>
                              </li>                              
                            </ul>
                          </td>
                        </tr>                      
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-end mt-3" v-if="!searchText">
                    <div class="pagination-wrap hstack gap-2">
                      <b-link class="page-item pagination-prev" href="#" v-if="page != 1" @click="page--">
                        Previous
                      </b-link>
                      <ul class="pagination listjs-pagination mb-0">
                        <li :class="{
                          active: pageNumber == page,
                          disabled: pageNumber == '...',
                        }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                          @click="page = pageNumber">
                          <b-link class="page" href="#">{{ pageNumber }}</b-link>
                        </li>
                      </ul>
                      <b-link class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                        Next
                      </b-link>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-12">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      >
                      </b-pagination>
                      <p class="mt-3">Current Page: {{ currentPage }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <ul class="pagination pagination-rounded justify-content-center mt-4">
                        <li class="page-item disabled">
                          <a href="#" class="page-link">
                            <i class="mdi mdi-chevron-left"></i>
                          </a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">1</a>
                        </li>
                        <li class="page-item active">
                          <a href="#" class="page-link">2</a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">3</a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">4</a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">5</a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">
                            <i class="mdi mdi-chevron-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </Loader>
    </Layout>
  </template>
